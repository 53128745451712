.calendar {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 360px;
  min-height: 200px;
  position: relative;
  font-size: 14px;
  font-family: var(--font-regular);
  letter-spacing: 0.2px;
  padding: 0px !important;
}

.calendar--hidden {
  visibility: hidden;
}

.monthsContainer {
  width: 100%;
  height: 100%;
  overflow: scroll;
  box-sizing: border-box;
  padding: 0 var(--sidebar-horizontal-padding);
  background-color: var(--ui-background-01);
  -webkit-overflow-scrolling: touch;
  border: 1px solid var(--color-border-low-contrast);
}

.monthsWrapper {
  width: 100%;
  position: relative;
}

.monthContainer {
  position: absolute;
  width: 100%;
}

.weekRow {
  display: grid;
  grid-template-columns: 25px repeat(7, 1.5fr) 50px;
  row-gap: 8px;
  height: 31px;
  color: var(--color-primary-noninteractive);
  opacity: 0.9;
  margin: 0 20px 0 20px;
  justify-items: center;
  align-items: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.summaryRow {
  display: grid;
  grid-template-columns: 42px repeat(7, 1fr) 80px;
  height: 44px;
  align-items: center;
  justify-items: center;
  font-size: 11px;
}

.weekRow > span:last-child {
  justify-self: flex-end;
}

.weekRow.currentWeek {
  border-bottom: 1px solid var(--color-selected-elements);
}

.weekRow.currentWeek .today {
  color: var(--color-text-on-interactive-fill-colors);
}

.error {
  color: var(--color-text-on-interactive-fill-colors);
}

.headers {
  color: var(--dark-grey);
  opacity: 1;
  top: 0px;
  background-color: var(--ui-background-01);
  margin: 0 0 5px 0;
  border-radius: 6px;
  z-index: 1;
  padding: 4px var(--sidebar-horizontal-padding) 2px;
}

.weekDay {
  text-transform: capitalize;
}

.weekNumber {
  font-size: 11px;
  letter-spacing: 0.1px;
  color: var(--dark-grey);
  opacity: 0.9;
  margin-right: 2px;
  justify-self: flex-start;
}

.monthRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 10px 8px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  color: black;
  background-color: var(--color-low-contrast-background);
  border-radius: 6px;
  height: 28px;
  letter-spacing: 0.4px;
}

.month {
  text-transform: capitalize;
}

.monthlyHours {
  font-weight: normal;
}

.weeklyHours {
  color: var(--dark-grey);
}

.day {
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.holiday {
  color: var(--medium-grey);
}

.sumModeSelect {
  outline: none;
  border: 1px solid var(--ui-03);
  border-radius: 4px;
  width: 80%;
}

.todayCircle {
  position: absolute;
  width: 27px;
  max-width: 100%;
  height: 27px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  background-color: var(--color-p300);
  z-index: -1;
}

.vacationCircle {
  position: absolute;
  width: 27px;
  max-width: 100%;
  height: 27px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  background-color: var(--color-k600);
  z-index: -2;
}

.vacationMidSquare {
  position: absolute;
  width: 100%;
  height: 27px;
  max-height: 31px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  background-color: var(--color-k600);
  z-index: -2;
}

.vacationCircleStart {
  position: absolute;
  width: 100%;
  height: 27px;
  top: 0;
  bottom: 0;
  right: -2px;
  margin-top: auto;
  margin-bottom: auto;
  border-top-left-radius: 27px;
  border-bottom-left-radius: 27px;
  background-color: var(--color-k600);
  z-index: -2;
}

.vacationCircleEnd {
  position: absolute;
  width: 100%;
  height: 27px;
  top: 0;
  bottom: 0;
  left: -2px;
  margin-top: auto;
  margin-bottom: auto;
  border-top-right-radius: 27px;
  border-bottom-right-radius: 27px;
  background-color: var(--color-k600);
  z-index: -2;
}

.floatingHolidayCircle {
  position: absolute;
  width: 27px;
  max-width: 100%;
  height: 27px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  background-color: var(--color-k800);
  z-index: -3;
}

.floatingHolidayMidSquare {
  position: absolute;
  width: 100%;
  height: 27px;
  max-height: 31px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  background-color: var(--color-k800);
  z-index: -3;
}

.floatingHolidayCircleStart {
  position: absolute;
  width: 100%;
  height: 27px;
  top: 0;
  bottom: 0;
  right: -2px;
  margin-top: auto;
  margin-bottom: auto;
  border-top-left-radius: 27px;
  border-bottom-left-radius: 27px;
  background-color: var(--color-k800);
  z-index: -3;
}

.floatingHolidayCircleEnd {
  position: absolute;
  width: 100%;
  height: 27px;
  top: 0;
  bottom: 0;
  left: -2px;
  margin-top: auto;
  margin-bottom: auto;
  border-top-right-radius: 27px;
  border-bottom-right-radius: 27px;
  background-color: var(--color-k800);
  z-index: -3;
}

.errorCircle {
  position: absolute;
  width: 27px;
  max-width: 100%;
  height: 27px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  background-color: var(--error-circle-fill);
  z-index: -2;
}

.positiveFlex {
  color: var(--ui-support-01);
}

.zeroFlex {
  color: var(--medium-grey);
}

.negativeFlex {
  color: black;
}
