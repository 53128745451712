.expected {
  grid-row: 2;
  grid-column: 3;

  margin: 9px 10px 10px 5px;
  text-align: right;
  font-size: 18px;
  line-height: 22px;
  font-family: var(--font-light);
  color: var(--dark-grey);
}

@media (min-width: 1000px) {
  .expected {
    grid-column: 3;
    letter-spacing: 1.9px;
    margin: 40px 55px 0px 10px;
    text-align: right;
  }
}

@media (min-width: 1200px) {
  .expected {
    grid-column: 4;
  }
}

@media (min-width: 1900px) {
  .expected {
    grid-column: 5;
    margin: 0 50px 0px 20px;
    text-align: left;
  }
}
