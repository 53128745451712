.heading {
  font-size: 16px;
  color: var(--color-primary-noninteractive);
}

.chart {
  width: 100%;
  height: 148px;
  box-sizing: border-box;
  padding-right: 20px;
}

.billingRatio {
  background-color: white;
  border-radius: 6px;
}
