.app {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}

.app--popup-open {
  overflow: hidden;
}

.app__content-divider {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: hidden;
}

.app__sidebar {
  min-width: var(--app-sidebar-width);
  max-width: var(--app-sidebar-width);

  height: calc(100vh - var(--header-height));
  border-left: 1px solid var(--app-sidebar-border-color);

  padding: 16px;
  box-sizing: border-box;
  display: block;
  overflow-y: auto;
  overflow-x: hidden;

  --sidebar-horizontal-padding: 0px;
}

.app__sidebar > *:not(.app__sidebar-close-popup-button) {
  padding-top: 16px;
  border-bottom: 1px solid var(--color-border-low-contrast);
  padding-bottom: 16px;
}
.app__sidebar > *:last-child {
  border-bottom: none;
}
.app__sidebar > *:first-child {
  padding-top: 0;
}

.app__sidebar--popup {
  background-color: var(--ui-background-01);
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100%;
  max-width: 100%;
  height: 100%;
  z-index: 9999;
  margin: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.app__sidebar--popup > *:not(.app__sidebar-close-popup-button) {
  padding: 16px;
  border-radius: 6px;
  margin-bottom: 16px;
  border-bottom: none;
}

@media (min-width: 500px) {
  .app__sidebar--popup {
    /* effectively max-width: 500px for elements to which this is applied */
    --sidebar-horizontal-padding: calc((100vw - 500px) / 2);
  }
}

.app__sidebar-close-popup-button {
  align-self: flex-end;
  height: 24px;
  width: 24px;
  outline: none;
  border: none;
  background: none;
  color: var(--dark-grey);
  cursor: pointer;
  z-index: 2;
  margin: 4px;
}

@media (max-width: calc(1000px - 1px)) {
  .app__sidebar--popup {
    justify-content: center;
  }
}

.app__sidebar--popup .app__sidebar-summary {
  min-height: unset;
  max-height: unset;
  flex: 1 0 auto;
}

.app__sidebar--popup .app__sidebar-summary .calendar__divider {
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
}
