.impersonationInput {
  width: 100%;
  margin-top: 6px;
}

.input {
  margin-right: 2px;
  padding-left: 4px;
  box-sizing: border-box;

  border: 1px solid #dedede;
  border-radius: 5px;
  background: #fff;
  display: flex;
  align-items: center;
  appearance: none;
  outline: none;
  width: 100%;
  height: 30px;
  font-size: 18px;
}

.openLink,
.changeToSelfLink {
  color: var(--color-primary-link);
  cursor: pointer;
  text-decoration: underline;
}

.openLink {
  margin-right: 10px;
}

.changeToSelfLink {
  opacity: 0.5;
}

.suggestionsContainer {
  width: calc(100% - 2px);
  border: 1px solid #ddd;
  border-top: none;
  margin-right: -2px;
  margin-top: -4px;
  padding-top: 4px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
}

.suggestions {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  max-height: 200px;
}

.suggestionsItem {
  height: 45px;
  display: flex;
  align-items: center;
  font-size: 14px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}

.suggestionsItem:hover {
  background: #ddd;
}

.suggestionsItemPortrait {
  height: 80%;
  margin-right: 4px;
  margin-left: 4px;
  border-radius: 4px;
}

.external {
  filter: grayscale(1);
}
