.holidayTable {
  font-family: var(--font-regular);
  font-size: 14px;
  background-color: var(--ui-background-01);
}

.holidayTable small {
  font-size: 12px;
  white-space: nowrap;
}

.holidayTable a {
  color: var(--color-primary-link);
}

.holidayTable table {
  width: 100%;
}

.holidayTable table th {
  color: var(--color-primary-noninteractive);
  font-size: 16px;
  flex: 1;
  opacity: 0.9;
}

.holidayTable table td {
  flex: 1;
  display: flex;
  align-items: center;
  font-weight: 400;
}

.holidayTable table tr {
  display: flex;
  margin-bottom: 4px;
}

.holidayTable table tr:first-child {
  margin-bottom: 8px;
}

.holidayTable table th:nth-child(1),
.holidayTable table td:nth-child(1) {
  flex: 3;
  text-align: left;
}

.holidayTable table th:nth-child(3),
.holidayTable table td:nth-child(3) {
  opacity: 0.9;
  width: 60px;
}

.holidayTable table th:not(:first-child),
.holidayTable table td:not(:first-child) {
  padding: 0px 0 0 5px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.infoIcon {
  margin-left: 4px;
}

.vacationsTooltip {
  position: relative;
  display: flex;
}

table tr.secondaryInfo {
  align-items: flex-start;
}

table tr.secondaryInfo > td {
  color: var(--medium-grey);
  font-size: 12px;
  padding-top: 4px;
}

.vacationsTooltip::after {
  padding: 16px 14px;
  background-color: #fff;
  color: var(--color-primary-noninteractive);
  border: 2px solid #f4f4f6;
  font-size: 14px;
  line-height: 17px;
  box-shadow: 0px 2px 6px rgba(0, 38, 58, 0.06);
  border-radius: 6px;
  display: none;
  width: 315px;
  height: auto;
  padding: 10px 15px;
  position: absolute;
  white-space: pre-line;
  word-wrap: break-word;
  z-index: 999;
  content: attr(data-tooltip);
  top: 0;
  left: -20px;
  transform: translateY(-100%);
}

.vacationsTooltip:hover::after {
  display: flex;
}

.tableHeaders > *:first-child {
  /* bold text*/
  font-weight: 600;
}

/* not first child */
.tableHeaders > *:not(:first-child) {
  font-size: 12px;
}

.tableHeaders > th {
  display: flex;
  align-items: center;
}

.separatorLarge {
  margin-top: 16px;
}

.separatorSmall {
  margin-top: 8px;
}

.infoButtonTooltip {
  background-color: var(--tooltip-background);
  padding: 8px;
}

.infoButtonTooltip a {
  color: var(--text-04)
}

.infoButtonTooltip b {
  font-weight: bold;
}

.holidayTable b {
  font-weight: bold;
}
