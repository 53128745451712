.navBar {
  background-color: white;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--admin-header-height);
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
  z-index: 999;
}

.navBar__items {
  display: grid;
  width: 100%;
  margin: 0 auto;
  grid-template-columns: 60px auto 60px;
  align-items: center;
  background: inherit;
}

.navBar__navLinks {
  display: flex;
  align-items: baseline;
  justify-content: center;
  height: 100%;
}

.navBar__navLink {
  font-family: var(--font-regular);
  font-size: 16px;
  color: var(--ui-05);
  text-decoration: none;
  padding: 21px 20px;
}

.navBar__navLink_selected {
  font-family: var(--font-medium);
  color: var(--color-primary-interactive);
  border-bottom: 3px solid var(--color-primary-interactive);
}

.navBar__user {
  width: 40px;
  height: 40px;
  background-position: 40% 40%;
  background-size: 40px 50px;
  border-radius: 100%;
}
