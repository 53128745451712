.adminClient h1 {
  font-family: var(--font-regular);
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 1em;
}

.adminUIDisclaimer {
  display: block;
  padding: 1em 0;
  width: 100%;
  text-align: center;
  background: lightyellow;
  font-size: 14px;
  line-height: 2em;
}

.controls {
  padding-bottom: 1em;
}

.controls label {
  font-family: var(--font-regular);
  font-size: 14px;
  width: max-content;
}

.selectContainer label {
  display: block;
  padding-bottom: 1em;
}

.link {
  border: none;
  color: var(--color-primary-link);
  cursor: pointer;
  font: inherit;
  outline: none;
  text-decoration: none;
}

.container {
  padding: 2em;
  background-color: var(--color-primary-page-background);
}

.button {
  border-radius: 6px;
  font-size: 16px;
  padding: 0.4em;
  border: 1px solid var(--color-primary-interactive);
}

.button:active {
  color: var(--color-text-on-interactive-fill-colors);
  background: var(--color-active-state);
  border: 1px solid var(--color-active-state);
}

.button:disabled {
  color: var(--color-text-on-interactive-fill-colors);
  background: var(--color-placeholder-text);
  border: 1px solid var(--color-placeholder-text);
  cursor: auto;
}

.button_primary {
  background: var(--color-primary-interactive);
  color: var(--color-text-on-interactive-fill-colors);
  cursor: pointer;
}

.button_secondary {
  background: var(--color-primary-page-background);
  color: var(--color-primary-interactive);
  cursor: pointer;
}

.errorText {
  color: var(--color-error);
}
