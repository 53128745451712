.inputContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.codeInput {
  grid-column: 1 / span 4;
}

.input {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 0;
  border-radius: 6px;
  padding-left: 10px;
  font-family: var(--font-light);
  -webkit-appearance: unset;
  box-shadow: 0 0 0 1px var(--color-border-medium-contrast) inset;
  font-size: 16px;
}

@media (min-width: 1000px) {
  .input {
    font-size: 15px;
    box-shadow: none;
  }

  .codeInput {
    grid-column: auto;
  }

  .tooltip {
    max-width: 250px !important;
  }
}

@media (min-width: 1200px) {
  .tooltip {
    max-width: 320px !important;
  }
}

.smallText {
  font-size: 13px;
}

.clearSelection {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  width: 19px;
  height: 19px;
}

.hidden {
  display: none;
}

.svg {
  width: 19px;
  height: 19px;
}

.path {
  stroke: var(--dark-grey);
  fill: transparent;
  stroke-linecap: round;
  stroke-width: 2;
}

.path:hover {
  stroke: var(--color-primary-noninteractive);
}

.hourCodes {
  position: absolute;
  top: 104px;
  left: 4px;
  width: auto;
  max-height: 25vh;
  background: white;
  z-index: 2;
  overflow: auto;
  flex: 1 1 0%;
  border: 1px solid var(--color-border-low-contrast);
  color: var(--color-primary-noninteractive);
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
  -webkit-overflow-scrolling: touch;
}

.hourCode {
  cursor: pointer;
  padding: 0 8px;
  white-space: nowrap;
  /* height is set in JS as an inline style */

  display: flex;
  align-items: center;
}

.tooltip {
  visibility: hidden;
}

:global(.tippy-arrow) {
  visibility: hidden;
}

.helpIcon {
  visibility: hidden;
}

@media (min-width: 1000px) {
  .mobile-tooltip {
    visibility: hidden;
  }

  .tooltip {
    background: var(--tooltip-background);
    visibility: visible;
    border-radius: 6px;
    color: white;
    padding: 12px 16px;
    margin-left: 6px;
    font-size: 16px;
    line-height: 1.5;
  }

  :global(.tippy-arrow) {
    color: var(--tooltip-background);
    visibility: visible;
  }

  .arrow,
  .arrow::before {
    position: absolute;
    width: 8px;
    height: 8px;
    background: inherit;
  }

  .arrow {
    visibility: hidden;
  }

  .arrow::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
  }

  .helpIcon {
    margin-left: auto;
    height: 24px;
    z-index: 9999;
    visibility: visible;
  }

  .hourCodeActive svg circle,
  .hourCode:hover .helpIcon svg circle {
    stroke: white;
  }

  .hourCodeActive svg path,
  .hourCode:hover .helpIcon svg path {
    fill: white;
  }
}

.hourCode strong {
  font-weight: bold;
  display: inline-block;
}

.active {
  background: var(--color-primary-noninteractive);
  color: #fff;
}

.inactive:nth-child(even) {
  background: var(--light-grey-bg);
}

.noResultsPlaceholder {
  position: absolute;
  top: 106px;
  left: 4px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
  padding: 16px 0;
  color: var(--dark-grey);
  text-align: center;
  background: #fff;
  z-index: 1;
}

@media (min-width: 800px) {
  .inactive:nth-child(even) {
    background-color: white;
  }

  .hourCode:hover {
    background: var(--color-primary-noninteractive);
    color: #fff;
  }

  .smallText {
    font-size: unset;
  }
}

@media (min-width: 1000px) {
  .codeInput {
    position: relative;
    border-bottom: none;
    height: 100%;
  }

  .inputContainer {
    vertical-align: top;
  }

  .input {
    border-radius: 0;
  }

  .hourCodes,
  .noResultsPlaceholder {
    min-width: 300px;
    top: 35px;
    left: unset;
    right: -150px;
  }

  .clearSelection {
    display: none;
  }
}

@media (min-width: 1100px) {
  .hourCodes,
  .noResultsPlaceholder {
    top: 35px;
    left: 4px;
    right: unset;
  }
}
