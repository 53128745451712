.timeRangeInput {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  overflow: hidden;
}

.time {
  width: 90%;
  height: 90%;
  border: none;
  border-radius: 6px;
  margin: 2px;
  padding: 0;
  font-family: var(--font-light);
  font-size: 16px;
  text-align: center;
  background: inherit;
  -webkit-appearance: unset;
}

.time:focus {
  outline-width: 0;
  box-shadow: 0 0 0 1px var(--color-border-medium-contrast) inset;
}

.separator {
  color: var(--dark-grey);
  width: 5px;
  height: 100%;
  display: flex;
  align-items: center;
}

@media (min-width: 1000px) {
  .timeRangeInput {
    padding-top: 1px;
    border-bottom: none;
  }

  .time {
    border-radius: 0;
    font-size: 15px;
  }

  .time,
  .separator {
    box-sizing: border-box;
    height: 100%;
  }
}