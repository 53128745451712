.calendarGuide {
  background-color: var(--ui-background-01);
  display: flex;
  flex-direction: row;
  margin-top: 3px;
  padding: 4px var(--sidebar-horizontal-padding) 2px;
  height: 46px;
  align-items: center;
}

.guideWrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 5px;
  height: 100%;
  margin-left: auto;
}

.guideBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 12px;
}

.guideBlock:last-child {
  margin-right: 0;
}

.vacationGuide {
  background-color: var(--color-k600);
  width: 35px;
  height: 25px;
  border-radius: 20px;
  margin-right: 8px;
}

.floatingHolidaysGuide {
  background-color: var(--color-k800);
  width: 35px;
  height: 25px;
  border-radius: 20px;
  margin-right: 8px;
}

.floatingHolidaysGuideDescription {
  width: 60px;
}

.errorGuide {
  background-color: var(--error-circle-fill);
  width: 35px;
  height: 25px;
  border-radius: 20px;
  margin-right: 8px;
}
