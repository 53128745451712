.menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #fff;
  width: 292px;
  height: 100%;
  padding: 0 35px;
  box-sizing: border-box;
  font-family: var(--font-light);
  font-size: 14px;
  transition: 0.5s;
  animation-name: menu__slideIn;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  overflow: auto;
}

.user {
  border-bottom: 1px solid var(--color-border-low-contrast);
  padding: 40px 0 25px;
}

.portrait {
  width: 70px;
  height: 70px;
  background-position: 40% 40%;
  background-size: 100% 125%;
  border-radius: 100%;
}

.mainActions {
  padding: 30px 0 10px;
}

.spacer {
  flex: 1 1 0%;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--night-sky-blue) 0.5;
  z-index: 1000;
  animation-name: menu__overlayFadeIn;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
}

.username {
  font-size: 13px;
  letter-spacing: 0.2px;
  margin-top: 30px;
  color: var(--color-primary-noninteractive);
}

.fullName {
  font-size: 22px;
  letter-spacing: 0.34px;
  margin-top: 8px;
  color: var(--pitch-black);
}

.contracts {
  margin-top: 20px;
}

.contractItem {
  display: grid;
  grid-template-columns: 48px 24px auto;
  margin-bottom: 4px;
  white-space: nowrap;
  opacity: 0.65;
}

.twoRows {
  display: flex;
  flex-wrap: wrap;
}

.current {
  opacity: 1;
}

.current .contractType {
  font-weight: bold;
}

.twoRows .contractType {
  margin-bottom: 2px;
}

.twoRows *:last-child {
  width: 100%;
  display: block;
}

.twoRows .contractTime {
  page-break-before: always;
  break-before: always;
}

.twoRows .contractCountry {
  display: block;
  padding-left: 4px;
}

.contractCountry {
  font-style: italic;
  font-weight: normal;
}

.contractTime {
  font-weight: normal;
}

.showVacationsButton,
.showBillingRatioButton,
.showSummaryButton,
.showAbsencesButton {
  width: 100%;
  height: 40px;
  margin-top: 14px;
}

.languages {
  margin-left: -16px;
  margin-top: 10px;
}

.toggle {
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: inherit;
  font-family: inherit;
  outline: none;
  padding: 0;

  margin-bottom: 6px;
}

.toggleCheckbox {
  margin-right: 15px;
}

.link {
  background: transparent;
  border: none;
  color: var(--color-primary-link);
  cursor: pointer;
  font-family: inherit;
  font-size: 16px;
  outline: none;
  padding: 0 0 20px 0;
  text-align: left;
  text-decoration: none;
}

.logout {
  padding-bottom: 50px;
}

/* NOTE: if 1400px is changed, it must be changed in some other places like in JS as well */
@media (min-width: 1000px) {
  .showVacationsButton,
  .showBillingRatioButton,
  .showSummaryButton,
  .showAbsencesButton {
    display: none;
  }
}

@media (max-width: calc(1000px - 1px)) {
  .vacationsToggle,
  .billingRatioToggle,
  .absencesToggle,
  .summaryToggle {
    display: none;
  }
}

@keyframes menu__overlayFadeIn {
  0% {
    background-color: rgba(0, 0, 0, 0);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

@keyframes menu__slideIn {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
