.validationMessages {
  display: flex;
  flex-flow: column;
  align-items: center;
  grid-column: 1 / span 4;
  margin-bottom: 10px;
  grid-row: 4;
}

.validationMessages span {
  text-align: center;
  color: var(--color-error);
  margin-top: 10px;
  font-size: 13px;
}

@media screen and (min-width: 1000px) {
  .validationMessages span {
    margin-top: 4px;
    font-size: 14px;
  }
}

.validationMessages button {
  -webkit-appearance: unset;
  border: none;
  outline: none;
  background: none;
  color: var(--color-error);
  font-size: 12px;
  font-family: var(--font-light);
  border: 2px solid var(--color-error);
  border-radius: 9999px;
  padding: 10px 20px;
  cursor: pointer;
  margin: 10px;
}

@media screen and (min-width: 1000px) {
  .validationMessages button {
    margin: 2px 10px;
  }

  .validationMessages {
    grid-column: 1/-1;
    grid-row: 2;
  }

  .validationMessages.startEndShown {
    grid-row: 3;
  }
}

.validationMessages button:hover {
  transform: scale(1.01);
}

.validationMessages button:active {
  background-color: var(--color-error);
  color: white;
}

.validationMessages button+span {
  margin-top: 5px;
}

.validationMessages:global(.workingDay--validationMessages) {
  margin-top: 10px;
}