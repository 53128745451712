.table th.sortable {
  cursor: pointer;
}

.sortIndicator {
  font-size: 0.7em;
  vertical-align: top;
  margin-left: 1em;
}

.table th.sortable:hover,
.table th.sortable:hover {
  color: var(--color-primary-hover-text);
}

.table {
  width: 100%;
  max-width: 1500px;
  margin-top: 1em;
  font-family: var(--font-regular);
  font-size: 15px;
  border-collapse: separate;
  color: var(--color-primary-noninteractive);
}

.table th:not(:first-child) {
  width: 150px;
}

.table td {
  padding: 0.5em;
}

.table tr:not(.editing):not(.editor):hover td,
.table tr:not(.editing):not(.editor):hover td * {
  color: var(--color-primary-hover-text);
}

.table tr:nth-child(even) {
  background-color: var(--ui-03);
}

.table tr.editable {
  cursor: pointer;
}

.table tr.editing td {
  border-top: 1px solid var(--color-primary-hover-text);
}

.table tr.editing td:first-child {
  border-left: 1px solid var(--color-primary-hover-text);
  border-top-left-radius: 10px;
}

.table tr.editing td:last-child {
  border-right: 1px solid var(--color-primary-hover-text);
  border-top-right-radius: 10px;
}

.table tr.editor td {
  border-bottom: 1px solid var(--color-primary-hover-text);
}

.table tr.editor td:first-child {
  border-left: 1px solid var(--color-primary-hover-text);
  border-bottom-left-radius: 10px;
}

.table tr.editor td:last-child {
  border-right: 1px solid var(--color-primary-hover-text);
  border-bottom-right-radius: 10px;
}

.table th {
  color: var(--color-primary-noninteractive);
  background-color: var(--color-primary-page-background);
  font-size: 16px;
  opacity: 0.9;
  text-align: left;
  position: sticky;
  top: var(--admin-header-height);
  line-height: 1.5em;
  padding: 0 0.5em;
  z-index: 999;
}

.table td.contentColumn {
  color: var(--color-secondary-body-text);
  font-weight: 400;
}
