:global(#daylist) {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
}

.scrollContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  overflow: scroll;
  -webkit-overflow-scrolling: touch;

  /* forces hardware rendering -- a lot better scrolling
    performance at least on Chrome when lots of days are rendered */
  will-change: transform;
}

.day {
  width: 100%;
  box-sizing: border-box;
}

.item {
  width: 100%;
}

.item > div:first-child {
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 1000px) {
  .item > div:first-child {
    padding-left: 37px;
    padding-right: 0px;
  }
}

@media (min-width: 1900px) {
  .item > div:first-child {
    padding-left: 110px;
    padding-right: 0px;
  }
}
