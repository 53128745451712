.employeeOrExternalOptionContainer {
  display: flex;
  align-items: flex-end;
  gap: 20px;
  font-size: 15px;
  margin-bottom: 20px;
}

.employeeOrExternalOptionLabel {
  display: inline-block;
  margin-left: 5px;
}

.unlocked-hours-table {
  width: 50%;
}

.unlocked-hours-table th {
  padding: 16px;
  color: var(--color-text-secondary);
  font-size: 14px;
  text-align: left;
}

.unlocked-hours-table tr:hover {
  background: var(--color-bg-muted);
}

.unlocked-hours-table-row-selected {
  background: var(--color-bg-muted);
}

.unlocked-hours-table-cell {
  padding: 16px !important;
  border: 1px solid var(--color-z700);
}

.unlocked-hours-table-cell-warning {
  background: var(--color-a900);
}

.users-table-empty {
  padding: 16px;
}
