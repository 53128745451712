.monthsContainer {
  width: 100%;
  height: 100%;
  overflow: scroll;
  box-sizing: border-box;
  padding: 0 var(--sidebar-horizontal-padding);
  -webkit-overflow-scrolling: touch;
  background-color: white;
  border-radius: 6px;
}

.monthContainer {
  border-bottom: 1px solid var(--medium-grey);
  padding-bottom: 10px;
  padding-top: 20px;
}

.monthRow {
  display: flex;
  justify-content: space-between;
  margin: 0 20px 15px;
  font-size: 18px;
  color: var(--color-primary-noninteractive);
  letter-spacing: 0.4px;
}

.month {
  text-transform: capitalize;
}

.monthlyHours {
  font-weight: bold;
}

.titleRow {
  display: flex;
  color: #555;
  justify-content: space-between;
  margin: 0 20px 8px;
  font-size: 15px;
  letter-spacing: 0.4px;
  font-weight: bold;
}

.taskRow {
  color: var(--color-primary-noninteractive);
  display: flex;
  justify-content: space-between;
  margin: 0 20px 8px 40px;
  font-size: 13px;
  letter-spacing: 0.4px;
}
