.languageButton {
  font-family: inherit;
  font-size: 16px;
  background: transparent;
  color: var(--medium-grey);
  display: inline-block;
  border: none;
  cursor: pointer;
  padding: 16px;
}

.current {
  color: var(--pitch-black);
}
