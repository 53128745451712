.expand {
  grid-row: 2;
  grid-column: 4;
  justify-self: flex-start;
  align-self: center;
}

.entries {
  margin-top: 0px;
  grid-row: 3;
  grid-column: 1 / 5;
}

@media (min-width: 1000px) {
  .expand {
    grid-column: 4;
    justify-self: flex-end;
    margin-right: 30px;
  }

  .entries {
    grid-column: 1 / 4;
  }
}

@media (min-width: 1200px) {
  .entries {
    margin-top: 10px;
    grid-column: 3;
  }
}

@media (min-width: 1900px) {
  .expand {
    justify-self: flex-end;
    margin-right: 10px;
  }
}
