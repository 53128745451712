.desktop {
  display: none;
}

@media (min-width: 800px) {
  .desktop {
    display: block;
  }
  .mobile {
    display: none;
  }
}
