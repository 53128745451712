.header {
  display: flex;
  align-items: center;
  position: relative;
  height: var(--header-height);
  z-index: 999;
  border-bottom: 1px solid var(--color-border-low-contrast);
  padding: 0px 32px;
}

.headerContent {
  display: flex;
  margin-left: auto;
  flex-direction: column-reverse;
}

.headerStatusInfo {
  display: flex;
  flex-direction: row;
  padding-bottom: 2px;
  align-items: center;
}

.headerSidebar {
  position: absolute;
  left: calc(100% - var(--app-sidebar-width));
  padding-left: 16px;
}

:global(.app--development) .header::before,
:global(.app--test) .header::before {
  padding: 0.2em 1em 0.3em;
  display: block;
  bottom: 0;
  left: 50%;
  font-size: 1.25rem;
  transform: translateX(-50%);
  z-index: 10000;
  border: 0;
  color: var(--text-04);
  position: fixed;
  background: var(--color-primary-noninteractive);
}

:global(.app--development) .header::before {
  content: 'THIS IS THE DEVELOPMENT ENVIRONMENT';
}

:global(.app--test) .header::before {
  content: 'THIS IS THE TESTING ENVIRONMENT';
}

:global(.app--impersonating) .header {
  background-color: var(--color-primary-noninteractive);
  color: white;
}

:global(.app--impersonating) .logo {
  filter: invert(100) contrast(10);
}

.desktopOnly {
  display: none;
}

@media (min-width: 1000px) {
  .mobileOnly {
    display: none;
  }

  .desktopOnly {
    display: unset;
  }

  .headerContent {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 0;
    width: calc(100% - var(--app-sidebar-width) - 32px);
  }

  .headerStatusInfo {
    position: absolute;
    padding-bottom: 0px;
    right: calc(var(--app-sidebar-width) + 30px);
  }
}

.logo {
  height: 30px;
  width: 100%;
}

.autoSaveContainer {
  background-color: var(--color-bg-default);
  top: 0;
  left: calc(32px + 22.25px);
  right: calc(32px + 34px);
  bottom: 0;
  overflow: hidden;

  position: absolute;
  display: flex;
  justify-content: center;
  justify-self: center;
  align-items: center;
  text-align: center;

  color: var(--color-success);
  opacity: 0;
  transition: opacity 1s ease-in-out 1s;
  pointer-events: none;
}

.visible {
  opacity: 1;
  transition: none;
  z-index: 2;
}

.longTransition {
  transition-delay: 5s;
}

.waiting {
  color: var(--color-waiting);
}

.success {
  color: var(--color-success);
}

.failure {
  color: var(--color-error);
}

.timeEntryStatusContainer {
  display: flex;
  flex-direction: column;

  @media (min-width: 800px) {
    flex-direction: row;
    align-items: center;
  }
}

.timeEntryStatus {
  align-items: center;
  font-family: var(--font-regular);
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  padding-right: 16px;
}
.centered {
  margin-left: auto;
  margin-right: auto;
}

.timeEntryStatus :not(:last-child) {
  margin-right: 12px;
}

.differenceAdjustment {
  display: flex;
  align-items: baseline;
  margin: 0 10px 0 10px;
  font-size: 12px;

  @media (min-width: 800px) {
    font-size: 13px;
    margin-right: 0;
  }

  .differenceAdjustmentHours {
    white-space: nowrap;
  }

  .differenceAdjustmentNote {
    display: inline-block;
    max-width: 180px;
    margin-right: -2px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 3px;

    @media (min-width: 800px) {
      max-width: 240px;
    }
  }

  .differenceAdjustmentDay {
    display: none;

    @media (min-width: 800px) {
      display: unset;
      margin-left: 2px;
    }
  }
}

@media (min-width: 1000px) {
  .timeEntryStatus {
    justify-content: flex-end;
    text-align: right;
    letter-spacing: 1.9px;
    padding-right: 0px;
  }

  .autoSaveContainer {
    left: 150px;
    right: calc(var(--app-sidebar-width) + 150px);
  }
}

@media (min-width: 1900px) {
  .headerStatusInfo {
    right: calc(var(--app-sidebar-width) + 100px);
  }
  .autoSaveContainer {
    left: 250px;
    right: calc(var(--app-sidebar-width) + 250px);
  }
}

.errorLink {
  margin-left: auto;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: normal;
  text-decoration: underline;
  cursor: pointer;
}

.red {
  color: var(--color-support-error);
}

.profilePicture {
  height: 34px;
  width: 34px;
  min-width: 34px;
  border-radius: 50%;
  background-position: 40% 40%;
  background-size: 100% 125%;
  margin-left: auto;
  cursor: pointer;
}
