.unlocked {
  background-color: var(--color-bg-default);
  cursor: pointer;
}

.locked {
  background-color: var(--ui-background-02);
  cursor: default;
  color: var(--color-text-secondary);
}
