.monthSummary {
  background: var(--color-accent-default);
  width: 100%;
  box-sizing: border-box;
  top: 0;

  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-items: center;

  padding-top: 24px;
  padding-bottom: 24px;

  border-bottom: 1px solid var(--color-border-low-contrast);
}

.monthSummary.isPastDue {
  background: var(--color-error-low-contrast);
}

.monthSummary.isPastDue .monthSummaryLockButton {
  background: var(--color-error);
}

.monthSummary.stickTop {
  position: sticky;
  z-index: 1;
}

.monthSummary.stickBottom {
  position: sticky;
  z-index: 1;
  bottom: 0;
}

.monthSummaryList {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  color: var(--color-text-primary);
  font-weight: 400;
}

.hoursSummary td {
  padding-right: 8px;
}

/* hoursSummary second column */
.hoursSummary td:nth-child(2) {
  text-align: right;
}

.topPadding td {
  padding-top: 8px;
}

.bottomPadding td {
  padding-bottom: 8px;
}

.boldText {
  font-weight: 600;
}

.absenceCode {
  color: var(--color-p300);
}

.monthSummaryMessage {
  display: none;
  justify-content: center;
  color: var(--color-text-secondary);
}

.monthSummaryLockButtonContainer {
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
  padding-right: 32px;
}

@media (min-width: 550px) {
  .monthSummaryLockButtonContainer {
    padding-top: 0;
    display: flex;
    justify-content: flex-end;
    padding-right: 32px;
  }
}

.monthSummaryLockButton {
  background: var(--color-p300);
  font-size: 14px;
}

.monthSummaryLockButton:disabled {
  color: var(--color-z500);
  background: var(--color-z700);
  border: 1px solid var(--color-z700);
  cursor: auto;
}

.monthSummaryUnlockButton {
  color: var(--color-p300);
  border: 1px solid var(--color-p300);
  background: var(--color-accent-default);
}

@media (min-width: 550px) {
  .monthSummary {
    flex-direction: row;
    align-items: center;
  }
}

@media (min-width: 1200px) {
  .monthSummaryMessage {
    display: flex;
  }
}

.title {
  font-size: 20px;
  padding-bottom: 12px;
}

.text {
  font-size: 14px;
}

.textProminent {
  color: var(--color-p300);
}
