.noConnectionIndicator {
  position: absolute;
  max-width: 90%;
  width: auto;
  min-width: 200px;
  min-height: 38px;
  top: 0px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  transform: translateY(-100%);
  transition: transform 0.5s;
  background-color: var(--color-error);
  color: white;
  font-size: 14px;
  z-index: 998;
  box-sizing: border-box;
  padding: 8px 15px;
}

.visible {
  transform: translateY(0);
}

.refreshButton {
  outline: none;
  -webkit-appearance: none;
  border: none;
  background-color: unset;

  margin-left: 8px;
  padding: 1px 10px;
  background-color: white;
  border: 2px solid white;
  border-radius: 4px;
  cursor: pointer;
  color: var(--color-error);
  font-size: 13px;
  height: 34px;
}

.refreshButton:hover {
  background-color: var(--color-error);
  color: white;
}

@media only screen and (min-width: 800px) {
  .refreshButton {
    height: unset;
  }
}
