.loadMore {
  width: 120px;
  min-height: 40px;
  background: var(--color-primary-button);
  color: white;
  border: 0;
  outline: none;
  border-radius: 50px;
  cursor: pointer;
  margin: 10px 0;
}
