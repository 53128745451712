.leaveEditor {
  background: var(--color-secondary-page-background);
  color: var(--color-primary-noninteractive);
}

.editorTitle {
  display: block;
  margin-bottom: 1em;
}

.leaveEditor label {
  display: block;
  color: var(--color-secondary-body-text);
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5em;
}

.leaveEditor input {
  display: block;
  margin: 0.5em 0;
  width: 4em;
  border: 1px solid var(--color-border-low-contrast);
  padding: 0.5em;
  border-radius: 0.25em;
}

.leaveEditor input:hover,
.leaveEditor input:focus {
  border: 1px solid var(--color-primary-interactive);
  outline: none;
}

.controlsColumn {
  position: relative;
}

.controls {
  position: absolute;
  display: flex;
  flex-direction: row;
  bottom: 0;
  right: 0;
  margin-right: 1em;
  margin-bottom: 1em;
  gap: 1em;
}

.saveError {
  text-align: right;
}
