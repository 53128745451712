.weekend {
  width: 100%;
  box-sizing: border-box;
  background-color: var(--color-bg-muted);
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  min-height: 53px;
  font-family: var(--font-light);
  font-size: 18px;
  border-bottom: 1px solid var(--color-border-low-contrast);
}

.weekend > div:last-child {
  margin-right: 0px;
}

.locked {
  cursor: default;
  color: var(--color-text-secondary);
}

@media (min-width: 1000px) {
  .weekend {
    min-height: 73px;
  }

  .weekend > div:last-child {
    margin-right: 30px;
  }
}

@media (min-width: 1900px) {
  .weekend {
    padding: 0px;
  }

  .weekend > div:last-child {
    margin-right: 110px;
  }
}
