.roundedButton {
  width: auto;
  height: 40px;
  padding: 0 20px;
  background: var(--color-primary-button);
  border: 0;
  border-radius: 20px;
  cursor: pointer;
  color: var(--color-text-on-interactive-fill-colors);
  font-family: var(--font-regular);
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  letter-spacing: 0.2px;
  overflow: hidden;
}
