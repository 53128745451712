.searchTermInput {
  margin-right: 10px;
  height: 30px;
  padding: 5px;
}

.controls {
  display: grid;
  max-width: 400px;
}

.controls p {
  margin: 10px 0;
  font-weight: bold;
  font-size: 16px;
}

.highlightContainer .highlight {
  font-weight: bold;
}

.resultSummary {
  margin: 10px 0;
}

.resultSummary>div {
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: bold;
}

.table td:nth-child(1) {
  width: 10%;
}

.table td:nth-child(2) {
  width: 10%;
}

.table th:nth-child(3),
.table td:nth-child(3) {
  text-align: right;
  padding-right: 40px;
}

.table td:nth-child(3) {
  width: 10%;
}

.table td:nth-child(4) {
  width: 30%;
  white-space: nowrap;
}

.table td:nth-child(5) {
  width: 40%;
}

.error {
  color: red;
  font-size: 24px;
  margin: 1em 0;
}
