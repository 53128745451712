.absenceCard {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px calc(var(--sidebar-horizontal-padding) + 20px) 10px;
  font-family: var(--font-regular);
  font-size: 14px;
  background-color: var(--ui-background-01);
  border-radius: 6px;
}

.used {
  color: var(--dark-grey);
  font-family: var(--font-regular);
  font-size: 14px;
}

.heading {
  color: var(--color-primary-noninteractive);
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 18px;
}

.noSickLeaves {
  margin-bottom: 10px;
}

.outer {
  margin-bottom: 10px;
}

.outer,
.month {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
}

.outer > div {
  justify-self: end;
}

.yearNumber {
  color: var(--color-primary-noninteractive)
}

.year {
  grid-column-start: span 4;
}

.year > div {
  font-size: 18px;
  margin: 2px 0;
}

.month .red {
  color: var(--color-error);
}

.month,
.year div {
  padding-bottom: 2px;
}

.month div:not(:first-of-type) {
  justify-self: end;
}
