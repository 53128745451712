.entry {
  position: relative;
  color: var(--pitch-black);
  font-size: 11px;
  text-decoration: none;
  transition: background 0.1s;
  display: grid;
  width: 100%;
  grid-template-columns: 54px auto;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
}

.entry {
  margin-bottom: 15px;
}

.entry.startEndShown {
  grid-template-columns: 130px 54px auto;
}

.entry.hourCodePolicy {
  background-color: var(--light-grey-bg);
  border: 10px solid var(--light-grey-bg);
  position: relative;
  left: -10px;
  border-radius: 6px;
}

.entry .useSameTimeZone {
  margin-left: 5px;
  margin-bottom: 5px;
  grid-column: 1 / -1;
  color: var(--dark-grey);
}

.unlocked {
  color: var(--color-primary-noninteractive);
}

.locked {
  color: var(--color-text-secondary);
}

@media (min-width: 1000px) {
  .entry {
    grid-template-columns: 60px 1fr 1.4fr 40px;
    grid-column-gap: 18px;
    min-height: var(--entry-height);
    margin-bottom: unset;
  }

  .entry:not(:first-of-type) {
    padding-top: 2px;
  }

  .entry.hourCodePolicy:not(:first-of-type) {
    margin: 5px 0;
  }

  .entry.hourCodePolicy {
    border-radius: 4px;
    box-shadow: 4px 0 0 0 var(--color-selected-elements) inset;
    border: none;
    padding: 5px 10px;
  }

  .entry .useSameTimeZone {
    grid-row: 2;
  }
}

@media (min-width: 1000px) {
  .entry {
    grid-template-columns: 60px 1fr 1.75fr 40px;
  }

  .entry.startEndShown {
    grid-template-columns: 120px 60px 1fr 1.5fr 40px;
  }
}

.item {
  box-shadow: 0 0 0 1px var(--color-border-medium-contrast) inset;
  border: none;
  border-radius: 6px;
  background: #fff;
  align-items: center;
  -webkit-appearance: unset;
  height: 45px;
  font-size: 16px;
}

.item:focus,
.item input:focus {
  outline-width: 0;
  box-shadow: 0 0 0 2px var(--color-primary-interactive) inset;
}

.item:global(.validationError),
.item input:global(.validationError) {
  box-shadow: 0 0 0 2px var(--color-error) inset;
}

@media (min-width: 1000px) {
  .item {
    border: 0;
    border-radius: 0;
    height: 26px;
    font-size: 15px;

    /* this is overriden in some input components where border-bottom
      needs to be applied to another element than .entry__item */
    border-bottom: 1px solid var(--dark-grey);
    box-shadow: none;
  }

  .item,
  .item input {
    background: inherit;
  }

  .item:focus,
  .item input:focus,
  .item:global(.validationError),
  .item input:global(.validationError) {
    outline: none;
    box-shadow: unset;
  }

  .item:global(.validationError),
  .item input:global(.validationError) {
    border-bottom: 1px solid var(--color-error);
  }
}

input:global(.validationError) {
  background-color: var(--color-error-low-contrast);
}

.delete {
  grid-column: 4;
  right: -50px;
  bottom: 10px;
  display: none;
  border: 0;
  background: transparent;
  cursor: pointer;
}

@media (min-width: 1000px) {
  .startEndShown .delete {
    grid-column: 5;
  }

  .delete.policyGroupDelete {
    grid-row: 1;
  }

  .delete {
    display: block;
    height: 100%;
  }
}

.viewEntryGrid {
  display: grid;

  font-size: 13px;
  font-weight: normal;
  letter-spacing: 0.2px;

  font-family: var(--font-regular);
  letter-spacing: 0.2px;
  height: 20px;
}

.entryColumnsPlusNoteAndStartEnd {
  grid-template-columns: min-content min-content 1fr 2fr;
}

.entryColumnsPlusNote {
  grid-template-columns: min-content 1fr 3fr;
}

.entryColumnsPlusStartEnd {
  grid-template-columns: min-content min-content 1fr;
}

.entryColumns {
  grid-template-columns: min-content 1fr;
}

.viewEntryGrid > span {
  padding-right: 6px;
}

.startEndTimes {
  width: 80px;

  display: inline-block;
  font-size: 14px;
  white-space: nowrap;

  flex-shrink: 0;
}

.timeUnlocked {
  color: var(--medium-grey);
}

.entryHours {
  width: 44px;
  flex-shrink: 0;
}

.hourCode {
  min-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.note {
  min-width: 40px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 20px;
}

.noteInput {
  padding: 0 8px 0 12px;
  grid-column: 1 / 5;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: var(--font-light);
}

.noteInput::placeholder {
  color: var(--medium-grey);
}

@media (min-width: 500px) {
  .viewEntryGrid > span {
    padding-right: 20px;
  }

  .hourCode {
    min-width: 140px;
  }

  .note {
    min-width: 80px;
  }
}

@media (min-width: 1000px) {
  .viewEntryGrid {
    font-size: 15px;
    max-width: calc(100% - 20px);
  }

  .noteInput {
    grid-column: auto;
  }

  .startEndTimes {
    font-size: 15px;
  }
}

@media (min-width: 1200px) {
  .viewEntryGrid {
    max-width: 100%;
  }
}
