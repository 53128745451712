/*
  STOP! Don't add new styles here anymore, add them into the CSS module variants instead wherever possible.
  If you touch any of these styles, consider moving them to CSS modules as well while you're at it.
*/

.workingDay {
  display: grid;
  grid-template-columns: auto 1fr auto auto;
  justify-content: space-between;
  padding: 5px 0px;
  font-family: var(--font-regular);
  font-size: 12px;
  border-bottom: 1px solid var(--color-border-low-contrast);
}

.workingDay--editing {
  cursor: auto;
  padding-bottom: 10px;
}

.workingDay--editing ::placeholder {
  color: var(--medium-grey);
  opacity: 1;
}

.workingDay--today {
  box-shadow: 5px 0 0 0 var(--color-selected-elements) inset;
}

.workingDay--validationErrors > .workingDay__today {
  color: var(--color-error);
}

.workingDay--validationErrors > .workingDay__sum {
  color: var(--color-error);
}

.workingDay--validationErrors.workingDay--today {
  box-shadow: 5px 0 0 0 var(--color-error) inset;
}

.workingDay--validationErrors > .workingDay__copyLastDay {
  background: none;
  border: 2px solid var(--color-error);
  color: var(--color-error);
}

.workingDay--validationErrors {
  box-shadow: 5px 0 0 0 var(--color-error) inset;
}

.workingDay--nonWorkingDay,
.workingDay--nonWorkingDay--today {
  background-color: var(--color-bg-muted);
}

.workingDay--nonWorkingDay > .workingDay__date {
  font-family: var(--font-light);
  font-size: 18px;
}

.workingDay--nonWorkingDay--today {
  box-shadow: 5px 0 0 0 var(--color-selected-elements) inset;
}

.workingDay--validationErrors,
.workingDay--nonWorkingDay > .workingDay--validationErrors {
  background-color: var(--color-error-low-contrast);
}

.workingDay__today {
  grid-row: 1;
  grid-column: 1;

  margin-top: 10px;
  color: var(--color-selected-elements);
  font-size: 16px;
  font-weight: 500;
}

.workingDay__date {
  grid-row: 2;
  grid-column: 1;

  margin: 10px 5px 10px 0;
  font-family: var(--font-light);
  font-size: 20px;
  text-transform: capitalize;
  letter-spacing: 0.5px;
  user-select: none;
}

.workingDay__copyLastDay {
  grid-row: 2;
  grid-column: 2 / 4;
  justify-self: center;
}

.workingDay__sum {
  width: 96px;
  grid-row: 2;
  grid-column: 4;

  margin: 10px 5px 10px 0;
  text-align: right;
  font-size: 22px;
  font-family: var(--font-regular);
  font-weight: bold;
}

.workingDay__entries {
  grid-row: 3;
  grid-column: 1 / 5;
}

@media (min-width: 1000px) {
  .workingDay__sum {
    grid-column: 3;
    padding-right: 28px;
  }
}

@media (min-width: 1200px) {
  .workingDay {
    padding: 12px 0;
    grid-template-columns: 0fr minmax(170px, max-content) 1fr min-content 0fr;
    /* min-height is calculated as two entries plus padding top padding of the second entry*/
    min-height: calc(var(--entry-height) * 2 + 2px);
  }

  .workingDay--today {
    box-shadow: 10px 0 0 0 var(--color-selected-elements) inset;
    padding-left: 0;
  }

  .workingDay--today .workingDay__date {
    padding-top: 10px;
  }

  .workingDay--validationErrors.workingDay--today {
    box-shadow: 10px 0 0 0 var(--color-error) inset;
  }

  .workingDay--validationErrors {
    box-shadow: 10px 0 0 0 var(--color-error) inset;
  }

  .workingDay > * {
    grid-row: 1;
  }

  .workingDay__date {
    grid-column: 2;
    font-size: 20px;
  }

  .workingDay__entries {
    grid-column: 3;
    margin-top: 0;
  }

  .workingDay--editing .workingDay__entries {
    align-self: flex-start;
  }

  .workingDay__expand {
    grid-column: 4;
    justify-self: flex-end;
    margin-right: 30px;
  }

  .workingDay__sum {
    grid-column: 4;
    font-size: 22px;
    letter-spacing: 1.9px;
    margin: 10px 30px 10px 10px;
    text-align: right;
    padding-right: 0px;
  }

  .workingDay__today {
    margin: -10px 10px 15px 0;
    grid-column: 2;
    line-height: 28px;
  }

  .workingDay--editing {
    padding-right: 0px;
  }

  .workingDay .workingDay__copyLastDay {
    grid-row: 1;
    grid-column: 2 / 4;
    font-size: 14px;
  }

  .workingDay--editing .workingDay__copyLastDay {
    grid-row: 1;
    grid-column: 4 / 5;
    margin-right: 20px;
  }
}

@media (min-width: 1900px) {
  .workingDay {
    grid-template-columns: 0px minmax(200px, max-content) 1fr minmax(100px, max-content) 100px;
  }

  .workingDay__today {
    margin: 10px 10px 10px 35px;
    grid-column: 1;
  }

  .workingDay--today .workingDay__date {
    padding-top: 0;
  }
  .workingDay--today {
    /* Wide layout for today must ignore the padding because of the 'Today' text which ignored padding  */
    padding-left: 0px !important;
    grid-template-columns: 110px minmax(200px, max-content) 1fr minmax(100px, max-content) 100px !important;
  }

  .workingDay__sum,
  .workingDay--editing .workingDay__sum {
    margin: 10px 0 10px 10px;
    text-align: right;
  }
}
