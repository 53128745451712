.policyGroup {
  grid-column: 1 / span 4;
}

.policyGroup .travel22 {
  display: grid;
  grid-template-columns: auto;
  row-gap: 5px;
}

.radioButtonGroup>p {
  margin: 15px 0 0 10px;
  color: var(--grey-90);
  font-size: 14px;
}

.radioButtonGroup {
  display: grid;
  row-gap: 5px;
  box-shadow: 0px 2px 6px rgba(0, 38, 58, 0.06);
}

.radioButtonGroup .selectableRadio {
  box-shadow: 0px 2px 6px rgba(0, 38, 58, 0.06);
  border: 1px solid var(--color-border-medium-contrast);
  border-radius: 6px;
  background: #fff;
  align-items: center;
  -webkit-appearance: unset;
  height: 59px;
  display: grid;
  grid-template-columns: 44px auto;
  cursor: pointer;
  user-select: none;
  font-size: 12px;
}

.buttonTitle {
  font-size: 14px;
}

.infoBlock p {
  margin-top: 15px;
  color: var(--grey-90);
  line-height: 20px;
}

.infoBlock p:first-child {
  line-height: 20px;
}

.infoBlock p:last-child {
  margin-bottom: 15px;
}

.infoBlock.radioButtonGroup {
  box-shadow: none;
}

.radioButtonGroup .selectableRadio.selected {
  box-shadow: 0 0 0 1px var(--color-selected-elements) inset;
}

.radioButtonGroup .selectableRadio .radioButton {
  box-shadow: 0 0 0 1px var(--color-border-medium-contrast) inset;
  border-radius: 50%;
  height: 18px;
  width: 18px;
  justify-self: center;
}

.radioButtonGroup .selectableRadio.selected .radioButton {
  box-shadow: 0 0 0 1px var(--color-primary-interactive) inset;
}

.radioButtonGroup .selectableRadio.selected .radioButton>div {
  background-color: var(--color-primary-interactive);
  border-radius: 50%;
  height: 8px;
  width: 8px;
  position: relative;
  top: 5px;
  left: 5px;
}

.radioButtonGroup .selectableRadio p:last-child:not(:first-of-type) {
  color: var(--ui-05);
  margin-top: 5px;
}

.radioButtonGroup .selectableRadio.selected>div>p:first-of-type {
  color: var(--color-primary-interactive);
}

@media (min-width: 1400px) {
  .policyGroup {
    grid-column: 1 / span 4;
  }

  .policyGroup.startEndShown {
    grid-column: 1 / span 5;
  }

  .policyGroup .travel22 {
    margin-top: 10px;
    margin-right: 15px;
  }

  .policyGroup .radioButtonGroup>p:first-child,
  .infoBlock.radioButtonGroup>p:first-child {
    grid-column: 1/-1;
  }

  .radioButtonGroup>p {
    margin-top: 5px;
  }

  .infoBlock.radioButtonGroup p:first-child {
    margin: 5px 0 0 10px;
  }

  .infoBlock p:last-child {
    grid-column: 1/-1;
  }

  .policyGroup .radioButtonGroup {
    grid-template-columns: 50% 50%;
    column-gap: 10px;
    margin-bottom: 10px;
    box-shadow: none;
  }

  .selectableRadio {
    grid-template-columns: 40px auto;
  }

  .radioButtonGroup .selectableRadio,
  .radioButtonGroup .selectableRadio.selected {
    background-color: var(--color-radiobutton-background);
    box-shadow: 0px 2px 6px rgb(0 38 58 / 6%);
    border-radius: 3px;
  }

  .radioButtonGroup .selectableRadio {
    border: 1px solid var(--ui-03);
  }

  .radioButtonGroup .selectableRadio.selected {
    border: 1px solid var(--color-selected-elements);
  }
}